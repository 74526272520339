*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Minecraftia";
  font-style: normal;
  font-weight: 400;
  src: url("./Fonts/Minecraft-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Minecraftia";
  font-style: normal;
  font-weight: 700;
  src: url("./Fonts/minecraft-bold.otf") format("opentype")
}

@font-face {
  font-family: "Minecraftia";
  font-style: italic;
  font-weight: 400;
  src: url("./Fonts/minecraft-italic.otf") format("opentype");
}

@font-face {
  font-family: "Minecraftia";
  font-style: italic;
  font-weight: 700;
  src: url("./Fonts/minecraft-bold-italic.otf") format("opentype");
}

@font-face {
  font-family: 'robotolight';
  src: url("./Fonts/Roboto-Light-webfont.eot");
  src: url("./Fonts/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), url("./Fonts/Roboto-Light-webfont.woff") format("woff"), url("./Fonts/Roboto-Light-webfont.ttf") format("truetype"), url("./Fonts/Roboto-Light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotoregular';
  src: url("./Fonts/Roboto-Regular-webfont.eot");
  src: url("./Fonts/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("./Fonts/Roboto-Regular-webfont.woff") format("woff"), url("./Fonts/Roboto-Regular-webfont.ttf") format("truetype"), url("./Fonts/Roboto-Regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotobold';
  src: url("./Fonts/Roboto-Bold-webfont.eot");
  src: url("./Fonts/Roboto-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("./Fonts/Roboto-Bold-webfont.woff") format("woff"), url("./Fonts/Roboto-Bold-webfont.ttf") format("truetype"), url("./Fonts/Roboto-Bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

#root {
  min-height: 80vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-title{
  font-size: 110%;
}

hr {
  height: 2px;
  width:25%;
  color:#444;
  background-color:#444;
  border: none;
  margin: 20px auto;
}

.textshadow {
  text-shadow: 1px 1px #111;
}

.page-header{
  color: white;
  font-family: robotobold;
  margin-top: 80px;
}

#search-header{
  text-align: center;
  margin-bottom: 110px;
}

fieldset {
  border:none;
}

#srchInp{
  margin-top: 30px;
  height: 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #666;
  color:white;
}

tr:not(:first-child) td{
  border-top: 1px solid #444;
}

.srchBtn{
  margin-top: 30px;
  background-color: rgb(221,159,78);
  padding: 8px 20px;
  color: white;
  border: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

a{
  color: #999;
}

a:hover{
  color:white;
}

#footer{
  text-align: center;
  bottom: 0;
  width: 100%;
  color:#666;
  margin-top: 20px;
  margin-bottom: 20px;
}

.small-input {
  width:100%;
  font-size: 24px;
  font-family: minecraftia;
  margin-bottom:10px;
  padding:5px;
  color: #aaa;
  border: none;
  background-color: #333333;
}

.small-input:focus {
  outline: none;
}