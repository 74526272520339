.PageSelectorNumber{
    padding: 0 10px;
}

.PageSelectorNumber:hover{
    color: #fff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.PageSelectorNumber.selected{
    color: #fff;
}