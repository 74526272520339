.Card{
  width:100%;
  border: 2px solid #202020;
  margin-bottom: 20px;
  Color: #999999;
}

.Card-Head{
  border-bottom: 2px solid #202020;
  font-size: 24px;
  font-family: robotolight;
  padding: 10px 16px;
  background-color: #333333;
  text-align: left;
}

.Card-Body{
  padding: 15px;
  background-color: #2e2e2e;
}

.Card-Head ul li, .CardTitle{
  display:inline;
  margin-right: 10px;
  cursor:pointer;
  text-align: center;
  -webkit-transition: border 0.1s;
  -moz-transition: border 0.1s;
  -o-transition: border 0.1s;
  transition: border 0.1s;
}

.CardTitle{
  width: 30px;
}

ul li:hover,.CardTitle:hover{
  color: #aaa;
  -webkit-transition: color 0.1s;
  -moz-transition: color 0.1s;
  -o-transition: color 0.1s;
  transition: color 0.1s;
}