.item_ {
    width: 32px;
    height: 32px;
    background-image: url(./imgs/blocks_items.png);
    display: inline-block;
    background-position:32px 32px;
  }
  .item_1 {background-position:0px 0px;}
.item_2 {background-position:-32px 0px;}
.item_3 {background-position:-64px 0px;}
.item_4 {background-position:-96px 0px;}
.item_5 {background-position:-128px 0px;}
.item_5_1 {background-position:-160px 0px;}
.item_5_2 {background-position:-192px 0px;}
.item_5_3 {background-position:-224px 0px;}
.item_6 {background-position:-256px 0px;}
.item_6_1 {background-position:0px -32px;}
.item_6_2 {background-position:-32px -32px;}
.item_6_3 {background-position:-64px -32px;}
.item_7 {background-position:-96px -32px;}
.item_8 {background-position:-128px -32px;}
.item_9 {background-position:-160px -32px;}
.item_10 {background-position:-192px -32px;}
.item_11 {background-position:-224px -32px;}
.item_12 {background-position:-256px -32px;}
.item_13 {background-position:0px -64px;}
.item_14 {background-position:-32px -64px;}
.item_15 {background-position:-64px -64px;}
.item_16 {background-position:-96px -64px;}
.item_17 {background-position:-128px -64px;}
.item_17_1 {background-position:-160px -64px;}
.item_17_2 {background-position:-192px -64px;}
.item_17_3 {background-position:-224px -64px;}
.item_18 {background-position:-256px -64px;}
.item_18_1 {background-position:0px -96px;}
.item_18_2 {background-position:-32px -96px;}
.item_18_3 {background-position:-64px -96px;}
.item_19 {background-position:-96px -96px;}
.item_20 {background-position:-128px -96px;}
.item_21 {background-position:-160px -96px;}
.item_22 {background-position:-192px -96px;}
.item_23 {background-position:-224px -96px;}
.item_24 {background-position:-256px -96px;}
.item_24_1 {background-position:0px -128px;}
.item_24_2 {background-position:-32px -128px;}
.item_25 {background-position:-64px -128px;}
.item_26 {background-position:-96px -128px;}
.item_27 {background-position:-128px -128px;}
.item_28 {background-position:-160px -128px;}
.item_29 {background-position:-192px -128px;}
.item_30 {background-position:-224px -128px;}
.item_31 {background-position:-256px -128px;}
.item_31_1 {background-position:0px -160px;}
.item_31_2 {background-position:-32px -160px;}
.item_31_3 {background-position:-64px -160px;}
.item_32 {background-position:-96px -160px;}
.item_33 {background-position:-128px -160px;}
.item_34 {background-position:-160px -160px;}
.item_35 {background-position:-192px -160px;}
.item_35_1 {background-position:-224px -160px;}
.item_35_2 {background-position:-256px -160px;}
.item_35_3 {background-position:0px -192px;}
.item_35_4 {background-position:-32px -192px;}
.item_35_5 {background-position:-64px -192px;}
.item_35_6 {background-position:-96px -192px;}
.item_35_7 {background-position:-128px -192px;}
.item_35_8 {background-position:-160px -192px;}
.item_35_9 {background-position:-192px -192px;}
.item_35_10 {background-position:-224px -192px;}
.item_35_11 {background-position:-256px -192px;}
.item_35_12 {background-position:0px -224px;}
.item_35_13 {background-position:-32px -224px;}
.item_35_14 {background-position:-64px -224px;}
.item_35_15 {background-position:-96px -224px;}
.item_36 {background-position:-128px -224px;}
.item_37 {background-position:-160px -224px;}
.item_38 {background-position:-192px -224px;}
.item_38_8 {background-position:-384px -768px;} /*<-------------*/
.item_39 {background-position:-224px -224px;}
.item_40 {background-position:-256px -224px;}
.item_41 {background-position:0px -256px;}
.item_42 {background-position:-32px -256px;}
.item_43 {background-position:-64px -256px;}
.item_43_1 {background-position:-96px -256px;}
.item_43_2 {background-position:-128px -256px;}
.item_43_3 {background-position:-160px -256px;}
.item_43_4 {background-position:-192px -256px;}
.item_43_5 {background-position:-224px -256px;}
.item_43_6 {background-position:-256px -256px;}
.item_43_7 {background-position:0px -288px;}
.item_43_8 {background-position:-32px -288px;}
.item_43_9 {background-position:-64px -288px;}
.item_44 {background-position:-96px -288px;}
.item_44_1 {background-position:-128px -288px;}
.item_44_2 {background-position:-160px -288px;}
.item_44_3 {background-position:-192px -288px;}
.item_44_4 {background-position:-224px -288px;}
.item_44_5 {background-position:-256px -288px;}
.item_44_6 {background-position:0px -320px;}
.item_44_7 {background-position:-32px -320px;}
.item_45 {background-position:-64px -320px;}
.item_46 {background-position:-96px -320px;}
.item_47 {background-position:-128px -320px;}
.item_48 {background-position:-160px -320px;}
.item_49 {background-position:-192px -320px;}
.item_50 {background-position:-224px -320px;}
.item_51 {background-position:-256px -320px;}
.item_52 {background-position:0px -352px;}
.item_53 {background-position:-32px -352px;}
.item_54 {background-position:-64px -352px;}
.item_55 {background-position:-96px -352px;}
.item_56 {background-position:-128px -352px;}
.item_57 {background-position:-160px -352px;}
.item_58 {background-position:-192px -352px;}
.item_59 {background-position:-224px -352px;}
.item_60 {background-position:-256px -352px;}
.item_61 {background-position:0px -384px;}
.item_62 {background-position:-32px -384px;}
.item_63 {background-position:-64px -384px;}
.item_64 {background-position:-96px -384px;}
.item_65 {background-position:-128px -384px;}
.item_66 {background-position:-160px -384px;}
.item_67 {background-position:-192px -384px;}
.item_68 {background-position:-224px -384px;}
.item_69 {background-position:-256px -384px;}
.item_70 {background-position:0px -416px;}
.item_71 {background-position:-32px -416px;}
.item_72 {background-position:-64px -416px;}
.item_73 {background-position:-96px -416px;}
.item_74 {background-position:-128px -416px;}
.item_75 {background-position:-160px -416px;}
.item_76 {background-position:-192px -416px;}
.item_77 {background-position:-224px -416px;}
.item_78 {background-position:-256px -416px;}
.item_79 {background-position:0px -448px;}
.item_80 {background-position:-32px -448px;}
.item_81 {background-position:-64px -448px;}
.item_82 {background-position:-96px -448px;}
.item_83 {background-position:-128px -448px;}
.item_84 {background-position:-160px -448px;}
.item_85 {background-position:-192px -448px;}
.item_86 {background-position:-224px -448px;}
.item_87 {background-position:-256px -448px;}
.item_88 {background-position:0px -480px;}
.item_89 {background-position:-32px -480px;}
.item_90 {background-position:-64px -480px;}
.item_91 {background-position:-96px -480px;}
.item_92 {background-position:-128px -480px;}
.item_93 {background-position:-160px -480px;}
.item_94 {background-position:-192px -480px;}
.item_95 {background-position:-224px -480px;}
.item_96 {background-position:-256px -480px;}
.item_97 {background-position:0px -512px;}
.item_97_1 {background-position:-32px -512px;}
.item_97_2 {background-position:-64px -512px;}
.item_98 {background-position:-96px -512px;}
.item_98_1 {background-position:-128px -512px;}
.item_98_2 {background-position:-160px -512px;}
.item_98_3 {background-position:-192px -512px;}
.item_99 {background-position:-224px -512px;}
.item_100 {background-position:-256px -512px;}
.item_101 {background-position:0px -544px;}
.item_102 {background-position:-32px -544px;}
.item_103 {background-position:-64px -544px;}
.item_104 {background-position:-96px -544px;}
.item_105 {background-position:-128px -544px;}
.item_106 {background-position:-160px -544px;}
.item_107 {background-position:-192px -544px;}
.item_108 {background-position:-224px -544px;}
.item_109 {background-position:-256px -544px;}
.item_110 {background-position:0px -576px;}
.item_111 {background-position:-32px -576px;}
.item_112 {background-position:-64px -576px;}
.item_113 {background-position:-96px -576px;}
.item_114 {background-position:-128px -576px;}
.item_115 {background-position:-160px -576px;}
.item_116 {background-position:-192px -576px;}
.item_117 {background-position:-224px -576px;}
.item_118 {background-position:-256px -576px;}
.item_119 {background-position:0px -608px;}
.item_120 {background-position:-32px -608px;}
.item_121 {background-position:-64px -608px;}
.item_122 {background-position:-96px -608px;}
.item_123 {background-position:-128px -608px;}
.item_124 {background-position:-160px -608px;}
.item_125 {background-position:-192px -608px;}
.item_125_1 {background-position:-224px -608px;}
.item_125_2 {background-position:-256px -608px;}
.item_125_3 {background-position:0px -640px;}
.item_126 {background-position:-32px -640px;}
.item_126_1 {background-position:-64px -640px;}
.item_126_2 {background-position:-96px -640px;}
.item_126_3 {background-position:-128px -640px;}
.item_127 {background-position:-160px -640px;}
.item_128 {background-position:-192px -640px;}
.item_129 {background-position:-224px -640px;}
.item_130 {background-position:-256px -640px;}
.item_131 {background-position:0px -672px;}
.item_132 {background-position:-32px -672px;}
.item_133 {background-position:-64px -672px;}
.item_134 {background-position:-96px -672px;}
.item_135 {background-position:-128px -672px;}
.item_136 {background-position:-160px -672px;}
.item_137 {background-position:-192px -672px;}
.item_138 {background-position:-224px -672px;}
.item_139 {background-position:-256px -672px;}
.item_139_1 {background-position:0px -704px;}
.item_140 {background-position:-32px -704px;}
.item_141 {background-position:-64px -704px;}
.item_142 {background-position:-96px -704px;}
.item_143 {background-position:-128px -704px;}
.item_144 {background-position:-160px -704px;}
.item_145 {background-position:-192px -704px;}
.item_145_1 {background-position:-224px -704px;}
.item_145_2 {background-position:-256px -704px;}
.item_146 {background-position:0px -736px;}
.item_147 {background-position:-32px -736px;}
.item_148 {background-position:-64px -736px;}
.item_149 {background-position:-96px -736px;}
.item_150 {background-position:-128px -736px;}
.item_151 {background-position:-160px -736px;}
.item_152 {background-position:-192px -736px;}
.item_153 {background-position:-224px -736px;}
.item_154 {background-position:-256px -736px;}
.item_155 {background-position:0px -768px;}
.item_155_1 {background-position:-32px -768px;}
.item_155_2 {background-position:-64px -768px;}
.item_155_3 {background-position:-96px -768px;}
.item_155_4 {background-position:-128px -768px;}
.item_156 {background-position:-160px -768px;}
.item_157 {background-position:-192px -768px;}
.item_158 {background-position:-224px -768px;}
.item_159 {background-position:-256px -768px;}
.item_159_1 {background-position:0px -800px;}
.item_159_2 {background-position:-32px -800px;}
.item_159_3 {background-position:-64px -800px;}
.item_159_4 {background-position:-96px -800px;}
.item_159_5 {background-position:-128px -800px;}
.item_159_6 {background-position:-160px -800px;}
.item_159_7 {background-position:-192px -800px;}
.item_159_8 {background-position:-224px -800px;}
.item_159_9 {background-position:-256px -800px;}
.item_159_10 {background-position:0px -832px;}
.item_159_11 {background-position:-32px -832px;}
.item_159_12 {background-position:-64px -832px;}
.item_159_13 {background-position:-96px -832px;}
.item_159_14 {background-position:-128px -832px;}
.item_159_15 {background-position:-160px -832px;}
.item_166 {background-position:-448px -768px;}
.item_170 {background-position:-192px -832px;}
.item_171 {background-position:-224px -832px;}
.item_171_1 {background-position:-256px -832px;}
.item_171_2 {background-position:0px -864px;}
.item_171_3 {background-position:-32px -864px;}
.item_171_4 {background-position:-64px -864px;}
.item_171_5 {background-position:-96px -864px;}
.item_171_6 {background-position:-128px -864px;}
.item_171_7 {background-position:-160px -864px;}
.item_171_8 {background-position:-192px -864px;}
.item_171_9 {background-position:-224px -864px;}
.item_171_10 {background-position:-256px -864px;}
.item_171_11 {background-position:0px -896px;}
.item_171_12 {background-position:-32px -896px;}
.item_171_13 {background-position:-64px -896px;}
.item_171_14 {background-position:-96px -896px;}
.item_171_15 {background-position:-128px -896px;}
.item_172 {background-position:-160px -896px;}
.item_173 {background-position:-192px -896px;}
.item_175 {background-position:-320px -768px;}
.item_175_1 {background-position: -416px -768px;}
.item_175_4 {background-position:-352px -768px;}
.item_188 {background-size: 32px;background-image:url(./imgs/188.png);}
.item_256 {background-position:288px 0px;}
.item_257 {background-position:-320px 0px;}
.item_258 {background-position:-352px 0px;}
.item_259 {background-position:-384px 0px;}
.item_260 {background-position:-416px 0px;}
.item_261 {background-position:-448px 2px;}
.item_262 {background-position:-480px 0px;}
.item_263 {background-position:-512px 0px;}
.item_263_1 {background-position:-544px 0px;}
.item_264 {background-position:288px -32px;}
.item_265 {background-position:-320px -32px;}
.item_266 {background-position:-352px -32px;}
.item_267 {background-position:-384px -32px;}
.item_268 {background-position:-416px -32px;}
.item_269 {background-position:-448px -32px;}
.item_270 {background-position:-480px -32px;}
.item_271 {background-position:-512px -32px;}
.item_272 {background-position:-544px -32px;}
.item_273 {background-position:288px -64px;}
.item_274 {background-position:-320px -64px;}
.item_275 {background-position:-352px -64px;}
.item_276 {background-position:-384px -64px;}
.item_277 {background-position:-416px -64px;}
.item_278 {background-position:-448px -64px;}
.item_279 {background-position:-480px -64px;}
.item_280 {background-position:-512px -64px;}
.item_281 {background-position:-544px -64px;}
.item_282 {background-position:288px -96px;}
.item_283 {background-position:-320px -96px;}
.item_284 {background-position:-352px -96px;}
.item_285 {background-position:-384px -96px;}
.item_286 {background-position:-416px -96px;}
.item_287 {background-position:-448px -96px;}
.item_288 {background-position:-480px -96px;}
.item_289 {background-position:-512px -96px;}
.item_290 {background-position:-544px -96px;}
.item_291 {background-position:288px -128px;}
.item_292 {background-position:-320px -128px;}
.item_293 {background-position:-352px -128px;}
.item_294 {background-position:-384px -128px;}
.item_295 {background-position:-416px -128px;}
.item_296 {background-position:-448px -128px;}
.item_297 {background-position:-480px -128px;}
.item_298 {background-position:-512px -128px;}
.item_299 {background-position:-544px -128px;}
.item_300 {background-position:288px -160px;}
.item_168 {background-position: -288px -768px;}
.item_168_2 {background-position: -288px -768px;}

.item_298 {background-image: url(./imgs/298.png);}
.item_299 {background-image: url(./imgs/299.png);}
.item_300 {background-image: url(./imgs/300.png);}
.item_301 {background-image: url(./imgs/301.png);}
.item_300_FFAA00 {background-position: -288px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_55FF55 {background-position: -320px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_5555FF {background-position: -352px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_FFFF55 {background-position: -384px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_FF5555 {background-position: -416px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_55FFFF {background-position: -448px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_7DC383 {background-position: -480px -800px;background-image: url(./imgs/blocks_items.png);}
.item_300_000000 {background-position: -512px -800px;background-image: url(./imgs/blocks_items.png);}

.item_302 {background-position:-352px -160px;}
.item_303 {background-position:-384px -160px;}
.item_304 {background-position:-416px -160px;}
.item_305 {background-position:-448px -160px;}
.item_306 {background-position:-480px -160px;}
.item_307 {background-position:-512px -160px;}
.item_308 {background-position:-544px -160px;}
.item_309 {background-position:288px -192px;}
.item_310 {background-position:-320px -192px;}
.item_311 {background-position:-352px -192px;}
.item_312 {background-position:-384px -192px;}
.item_313 {background-position:-416px -192px;}
.item_314 {background-position:-448px -192px;}
.item_315 {background-position:-480px -192px;}
.item_316 {background-position:-512px -192px;}
.item_317 {background-position:-544px -192px;}
.item_318 {background-position:288px -224px;}
.item_319 {background-position:-320px -224px;}
.item_320 {background-position:-352px -224px;}
.item_321 {background-position:-384px -224px;}
.item_322 {background-position:-416px -224px;}
.item_322_1 {background-position:-448px -224px;}
.item_323 {background-position:-480px -224px;}
.item_324 {background-position:-512px -224px;}
.item_325 {background-position:-544px -224px;}
.item_326 {background-position:288px -256px;}
.item_327 {background-position:-320px -256px;}
.item_328 {background-position:-352px -256px;}
.item_329 {background-position:-384px -256px;}
.item_330 {background-position:-416px -256px;}
.item_331 {background-position:-448px -256px;}
.item_332 {background-position:-480px -256px;}
.item_333 {background-position:-512px -256px;}
.item_334 {background-position:-544px -256px;}
.item_335 {background-position:288px -288px;}
.item_336 {background-position:-320px -288px;}
.item_337 {background-position:-352px -288px;}
.item_338 {background-position:-384px -288px;}
.item_339 {background-position:-416px -288px;}
.item_340 {background-position:-448px -288px;}
.item_341 {background-position:-480px -288px;}
.item_342 {background-position:-512px -288px;}
.item_343 {background-position:-544px -288px;}
.item_344 {background-position:288px -320px;}
.item_345 {background-position:-320px -320px;}
.item_346 {background-position:-352px -320px;}
.item_347 {background-position:-384px -320px;}
.item_348 {background-position:-416px -320px;}
.item_349 {background-position:-448px -320px;}
.item_349_2 {background-position:-544px -768px;}
.item_349_3 {background-position:-512px -768px;}
.item_350 {background-position:-480px -320px;}
.item_351 {background-position:-512px -320px;}
.item_351_1 {background-position:-544px -320px;}
.item_351_2 {background-position:288px -352px;}
.item_351_3 {background-position:-320px -352px;}
.item_351_4 {background-position:-352px -352px;}
.item_351_5 {background-position:-384px -352px;}
.item_351_6 {background-position:-416px -352px;}
.item_351_7 {background-position:-448px -352px;}
.item_351_8 {background-position:-480px -352px;}
.item_351_9 {background-position:-512px -352px;}
.item_351_10 {background-position:-544px -352px;}
.item_351_11 {background-position:288px -384px;}
.item_351_12 {background-position:-320px -384px;}
.item_351_13 {background-position:-352px -384px;}
.item_351_14 {background-position:-384px -384px;}
.item_351_15 {background-position:-416px -384px;}
.item_352 {background-position:-448px -384px;}
.item_353 {background-position:-480px -384px;}
.item_354 {background-position:-512px -384px;}
.item_355 {background-position:-544px -384px;}
.item_356 {background-position:288px -416px;}
.item_357 {background-position:-320px -416px;}
.item_358 {background-position:-352px -416px;}
.item_359 {background-position:-384px -416px;}
.item_360 {background-position:-416px -416px;}
.item_361 {background-position:-448px -416px;}
.item_362 {background-position:-480px -416px;}
.item_363 {background-position:-512px -416px;}
.item_364 {background-position:-544px -416px;}
.item_365 {background-position:288px -448px;}
.item_366 {background-position:-320px -448px;}
.item_367 {background-position:-352px -448px;}
.item_368 {background-position:-384px -448px;}
.item_369 {background-position:-416px -448px;}
.item_370 {background-position:-448px -448px;}
.item_371 {background-position:-480px -448px;}
.item_372 {background-position:-512px -448px;}
.item_373 {background-position:-544px -448px;}
.item_374 {background-position:288px -480px;}
.item_375 {background-position:-320px -480px;}
.item_376 {background-position:-352px -480px;}
.item_377 {background-position:-384px -480px;}
.item_378 {background-position:-416px -480px;}
.item_379 {background-position:-448px -480px;}
.item_380 {background-position:-480px -480px;}
.item_381 {background-position:-512px -480px;}
.item_382 {background-position:-544px -480px;}
.item_383_50 {background-position:288px -512px;}
.item_383_51 {background-position:-320px -512px;}
.item_383_52 {background-position:-352px -512px;}
.item_383_54 {background-position:-384px -512px;}
.item_383_55 {background-position:-416px -512px;}
.item_383_56 {background-position:-448px -512px;}
.item_383_57 {background-position:-480px -512px;}
.item_383_58 {background-position:-512px -512px;}
.item_383_59 {background-position:-544px -512px;}
.item_383_60 {background-position:288px -544px;}
.item_383_61 {background-position:-320px -544px;}
.item_383_62 {background-position:-352px -544px;}
.item_383_65 {background-position:-384px -544px;}
.item_383_66 {background-position:-416px -544px;}
.item_383_90 {background-position:-448px -544px;}
.item_383_91 {background-position:-480px -544px;}
.item_383_92 {background-position:-512px -544px;}
.item_383_93 {background-position:-544px -544px;}
.item_383_94 {background-position:288px -576px;}
.item_383_95 {background-position:-320px -576px;}
.item_383_96 {background-position:-352px -576px;}
.item_383_98 {background-position:-384px -576px;}
.item_383_100 {background-position:-416px -576px;}
.item_383_120 {background-position:-448px -576px;}
.item_384 {background-position:-480px -576px;}
.item_385 {background-position:-512px -576px;}
.item_386 {background-position:-544px -576px;}
.item_387 {background-position:288px -608px;}
.item_388 {background-position:-320px -608px;}
.item_389 {background-position:-352px -608px;}
.item_390 {background-position:-384px -608px;}
.item_391 {background-position:-416px -608px;}
.item_392 {background-position:-448px -608px;}
.item_393 {background-position:-480px -608px;}
.item_394 {background-position:-512px -608px;}
.item_395 {background-position:-544px -608px;}
.item_396 {background-position:288px -640px;}
.item_397_0 {background-position:-320px -640px;}
.item_397_1 {background-position:-352px -640px;}
.item_397_2 {background-position:-384px -640px;}
.item_397_3 {background-position:-416px -640px;}
.item_397_4 {background-position:-448px -640px;}
.item_398 {background-position:-480px -640px;}
.item_399 {background-position:-512px -640px;}
.item_400 {background-position:-544px -640px;}
.item_401 {background-position:288px -672px;}
.item_402 {background-position:-320px -672px;}
.item_403 {background-position:-352px -672px;}
.item_404 {background-position:-384px -672px;}
.item_405 {background-position:-416px -672px;}
.item_406 {background-position:-448px -672px;}
.item_407 {background-position:-480px -672px;}
.item_408 {background-position:-512px -672px;}
.item_417 {background-position:-544px -672px;}
.item_418 {background-position:288px -704px;}
.item_419 {background-position:-320px -704px;}
.item_420 {background-position:-352px -704px;}
.item_421 {background-position:-384px -704px;}
.item_423 {background-position:-512px -736px;}
.item_430 {background-position:-480px -768px;}
.item_2256 {background-position:-416px -704px;}
.item_2257 {background-position:-448px -704px;}
.item_2258 {background-position:-480px -704px;}
.item_2259 {background-position:-512px -704px;}
.item_2260 {background-position:-544px -704px;}
.item_2261 {background-position:288px -736px;}
.item_2262 {background-position:-320px -736px;}
.item_2263 {background-position:-352px -736px;}
.item_2264 {background-position:-384px -736px;}
.item_2265 {background-position:-416px -736px;}
.item_2266 {background-position:-448px -736px;}
.item_2267 {background-position:-480px -736px;}

/* potions */
.item_373_0 {background-position:0px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16 {background-position:-32px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_32 {background-position:-64px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_64 {background-position:-96px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8193 {background-position:-128px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8194 {background-position:-160px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8195 {background-position:-192px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8196 {background-position:-224px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8197 {background-position:-256px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8198 {background-position:-288px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8200 {background-position:-320px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8201 {background-position:-352px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8202 {background-position:-384px 0px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8203 {background-position:-160px -128px;background-image:url(./imgs/blocks_items_potions.png);} 
.item_373_8204 {background-position:0px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8206 {background-position:-32px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8225 {background-position:-64px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8226 {background-position:-96px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8228 {background-position:-128px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8229 {background-position:-160px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8233 {background-position:-192px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8236 {background-position:-224px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8257 {background-position:-256px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8258 {background-position:-288px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8259 {background-position:-320px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8260 {background-position:-352px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8262 {background-position:-384px -32px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8264 {background-position:0px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8265 {background-position:-32px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8266 {background-position:-64px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_8270 {background-position:-96px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16384 {background-position:-128px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16385 {background-position:-160px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16386 {background-position:-192px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16387 {background-position:-224px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16388 {background-position:-256px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16389 {background-position:-288px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16390 {background-position:-320px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16392 {background-position:-352px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16393 {background-position:-384px -64px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16394 {background-position:0px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16396 {background-position:-32px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16398 {background-position:-64px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16417 {background-position:-96px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16418 {background-position:-128px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16420 {background-position:-160px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16421 {background-position:-192px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16425 {background-position:-224px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16428 {background-position:-256px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16449 {background-position:-288px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16450 {background-position:-320px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16451 {background-position:-352px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16452 {background-position:-384px -96px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16454 {background-position:0px -128px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16456 {background-position:-32px -128px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16457 {background-position:-64px -128px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16458 {background-position:-96px -128px;background-image:url(./imgs/blocks_items_potions.png);}
.item_373_16462 {background-position:-128px -128px;background-image:url(./imgs/blocks_items_potions.png);}