.progress-bar {
    height: 20px;
    margin-top: -1px;
    margin-bottom: 10px;
    margin-left: -2px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #202020 !important;
    border: 2px solid #202020;
    border-left:none;
    width:100%;
}

.ProgressBar {
    margin-bottom:10px;
}

.progress-icon {
    padding:2px;
    height: 40px;
    width: 40px;
    border-radius: 25%;
    border-bottom-right-radius: 0%;
    border: 2px solid #202020;
    display: inline-block;
    z-index: 10;
}

.progress-icon * {
    z-index:30;
}

.progress-name {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    height: 0px;
    font-size: 16px;
}

.progress-bar-progress {
    z-index: 20;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.progress-bar-text {
    position: absolute;
    margin-top:-17px;
    margin-left:0px;
    width:245px;
    text-align: center;
    color:#fff;
    font-size: 16px;
}

/*
.progress-bar-text {
    position: absolute;
    margin-top:-17px;
    margin-left:78px;
    color:#fff;
    font-size: 16px;
}
*/

.xpbg, .xpbg::before {
    background-color: #50caca;
}

.goldbg, .goldbg::before {
    background-color: #d9a334;
}

.renownbg, .renownbg::before {
    background-color: #8f58ff;
}