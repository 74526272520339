div.nav div {
    display: inline-block !important;
    width:200px;
    padding:10px;
    text-align: center;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

div.nav div:hover {
    color:#ccc;
    cursor: pointer;
}

div.nav div.active {
    font-family: robotobold;
}

div.nav {
    width:100%;
    background-color: #2b2b2b;
    border-bottom: 2px solid #222;
    color: #999999;
    padding:10px;
}